import React, { useState } from "react";
import axios from "axios";
import { Form, Button, Container } from "react-bootstrap";

const Contactus = () => {
  const [serverState, setServerState] = useState({
    submitting: false,
    status: null,
  });
  const handleServerResponse = (ok, msg, form) => {
    setServerState({
      submitting: false,
      status: {
        ok,
        msg,
      },
    });
    if (ok) {
      form.reset();
    }
  };
  const handleOnSubmit = (e) => {
    e.preventDefault();
    const form = e.target;
    setServerState({
      submitting: true,
    });
    axios({
      method: "post",
      url: "https://formtest.cflmstest.com/api/contact/email.php",
      data: new FormData(form),
    })
      .then((r) => {
        handleServerResponse(true, "Thanks!", form);
      })
      .catch((r) => {
        handleServerResponse(false, r.response.data.error, form);
      });
  };

  return (
    <Container>
      <div className="font-weight-bold">
        <h3> Please fill in the form below. </h3>{" "}
        <Form onSubmit={handleOnSubmit}>
          <Form.Row>
            <Form.Group controlId="formGridName" className="inputarea" required>
              <Form.Label> Your Name(required) </Form.Label>{" "}
              <Form.Control
                type="text"
                name="name"
                placeholder="Jon Doe"
                size="lg"
              />
            </Form.Group>{" "}
          </Form.Row>{" "}
          <Form.Row>
            <Form.Group
              controlId="formGridEmail"
              className="inputarea"
              required
            >
              <Form.Label> Your Email(required) </Form.Label>{" "}
              <Form.Control
                type="email"
                name="email"
                placeholder="example@domain.com"
              />
            </Form.Group>{" "}
          </Form.Row>{" "}
          <Form.Row>
            <Form.Group
              controlId="formGridPhone"
              className="inputarea"
              required
            >
              <Form.Label> Phone Number </Form.Label>{" "}
              <Form.Control
                type="text"
                name="phone"
                placeholder="(xxx)xxx-xxxx"
              />
            </Form.Group>{" "}
          </Form.Row>
          <Form.Row>
            <Form.Group
              controlId="exampleForm.ControlTextarea1"
              className="inputarea"
            >
              <Form.Label> Your Question(required) </Form.Label>{" "}
              <Form.Control size="lg" type="textbox" name="message" required />
            </Form.Group>{" "}
          </Form.Row>
          <Button
            className="btn btn-secondary"
            variant="primary"
            type="submit"
            disabled={serverState.submitting}
          >
            Send{" "}
          </Button>{" "}
          {serverState.status && (
            <p className={!serverState.status.ok ? "errorMsg" : ""}>
              {" "}
              {serverState.status.msg}{" "}
            </p>
          )}{" "}
        </Form>{" "}
      </div>{" "}
    </Container>
  );
};

export default Contactus;


// https://script.google.com/macros/s/AKfycbxnaAKSZcUYrecOEATZzYnctzMpnF6xHJ6mX-2JM_LmaeN23NU1/exec //