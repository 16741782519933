import { Link } from "gatsby";
import React from "react";
import { Card, CardDeck, Col, Container, Row } from "react-bootstrap";
import { FaFacebookF } from "react-icons/fa";
import Contactus from "../components/ContactForm";
import Contacthero from "../components/hero/Contacthero";
import Layout from "../components/Layout";

const contact = () => {
  return (
    <Layout>
      <Contacthero />
      <Container className="contactpage">
        <Row>
          <Col className="contactinfo">
            <p>Young Home Inspections</p>
            <p>
              We look forward to learning how we can best serve you. There are
              plenty of ways to get ahold of us. Please reach out to us by the
              method of your choice.
            </p>
            <p>Contact Information</p>
            <p>New Port Richey, FL 34652</p>
            <p>Land O' Lakes, FL 34637</p>
            <a href="tel:+17277335186">
              <p>(727) 733-5186</p>
            </a>
            <p> Monday - Friday 7:30 a.m. - 6:00 p.m.</p>
            <p>Social Media</p>
            <a href="https://www.facebook.com/YoungHomeInspections/">
              <FaFacebookF className="sociallink" />
            </a>
          </Col>
          <Col className="contactform">
            <Contactus />
          </Col>
        </Row>
      </Container>
      <div className="contact-card cta-set cta-contact">
        <div>
          <CardDeck className="mb-30 ">
            <Card className="text-center card-contact">
              <Card.Body>
                <Card.Title>Contact By Email</Card.Title>
                <Card.Text>
                  Need to get ahold of a licensed inspector? You can contact us
                  by email at
                  <a
                    href="mailto:service@younginspections.com"
                    className="contact-link"
                  >
                    <p>service@younginspections.com</p>
                  </a>
                </Card.Text>
              </Card.Body>
            </Card>
            <Card className="text-center card-contact">
              <Card.Body>
                <Card.Title>Contact By Phone</Card.Title>
                <Card.Text>
                  Need to get ahold of us quickly?
                  <br />
                  Give us a call when you are ready.
                  <br />
                  <a href="tel:+7277335186" className="contact-link">
                    (727)733-5186
                  </a>
                </Card.Text>
              </Card.Body>
            </Card>
            <Card className="text-center card-contact">
              <Card.Body>
                <Card.Title>Check Out Our FAQS</Card.Title>
                <Card.Text>
                  Looking for more information?
                  <br />
                  Check out our frequently asked questions
                  <br />
                  <Link to="/faqs" className="contact-link">
                    Click Here
                  </Link>
                </Card.Text>
              </Card.Body>
            </Card>
          </CardDeck>
        </div>
      </div>
    </Layout>
  );
};

export default contact;
